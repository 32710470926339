/*
 Copyright © mSIGNIA, Incorporated, 2007.  All rights reserved.

 Software is protected by one or more of U.S. Patent No. 9,559,852, 9294448, 8,817,984,
 international patents and others pending. For more information see www.mSIGNIA.com.  User agrees
 that they will not them self, or through any affiliate, agent or other third-party, entity or
 other business structure remove, alter, cover or obfuscate any copyright notices or other
 proprietary rights notices of mSIGNIA or its licensors.  User agrees that they will not them
 self, or through any affiliate, agent or other third party, entity or other business structure
 (a) reproduce, sell, lease, license or sublicense this software or any part thereof, (b)
 decompile, disassemble, re-program, reverse engineer or otherwise attempt to derive or modify
 this software in whole or in part, (c) write or develop any derivative software or any other
 software program based upon this software, (d) provide, copy, transmit, disclose, divulge, or
 make available to, or permit use of this software by any third party or entity or machine without
 software owner's prior written consent, (e) circumvent or disable any security or other
 technological features or measures used by this software.
*/

import { Pipe, PipeTransform } from '@angular/core';
import { DATING_GOAL_KEYS } from "../models/user-model";

@Pipe({
  name: 'datingGoal'
})
export class DatingGoalPipe implements PipeTransform {

  transform(value: string | undefined, ...args: any[]): string {
    if (!value) return '';
    let result = value;

    switch (value) {
      case DATING_GOAL_KEYS.ONE_NIGHT_STAND:
        result = 'one-night stand';
        break;
      case DATING_GOAL_KEYS.SHORT_TERM_FUN:
        result = 'short-term fun';
        break;
      case DATING_GOAL_KEYS.LONG_TERM_STORY:
        result = 'long-term story';
        break;
      case DATING_GOAL_KEYS.LOVE_RELATIONSHIPS:
        result = 'love and relationships';
        break;
      case DATING_GOAL_KEYS.NOT_SURE_YET:
        result = 'Not sure yet';
        break;
      default:
        break;
    }

    return result;
  }

}

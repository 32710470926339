<h1 class="question">
  Where are you located?
</h1>

<p class="info">
  We need this information to match you with a potential partners nearby
</p>

<div class="location-container">
  <div class="location-input">
    <input
      type="text"
      [(ngModel)]="location"
      (input)="onSearchChange()"
      placeholder="Enter your city"
      class="location-text-field"
    />
    <div class="location-button">
      <i (click)="getUserLocation()" class="icon icon-location"></i>
    </div>
  </div>

  <ul *ngIf="suggestions.length > 0" class="suggestions-list">
    <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
      {{ suggestion.display_name }}
    </li>
  </ul>
</div>

<button [disabled]="!selectedCity" (click)="continue($event)"
        class="button button-primary size-normal" id="btn-continue-location">
  Continue
</button>

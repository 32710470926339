import { Injectable } from '@angular/core';
import { init, track, Identify, identify as setIdentify } from '@amplitude/analytics-browser';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor() {
    init('aecc658dca785c3a8b287a5d677e88aa', undefined, {
      defaultTracking: false
    });
  }

  trackEvent(eventName: string, eventProperties?: Record<string, any>): void {
    track(eventName, eventProperties);
  }

  setUserProperties(userProperties: Record<string, any>): void {
    const identify = new Identify();
    Object.keys(userProperties).forEach((key) => {
      identify.set(key, userProperties[key]);
    });

    // Apply the user properties with identify
    setIdentify(identify);
  }
}

import { RouterModule, Routes } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { QuestionsFlowComponent } from "./pages/questions-flow/questions-flow.component";
import { EmailComponent } from "./pages/email/email.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CheckoutErrorComponent } from "./pages/checkout-error/checkout-error.component";
import { FindIdealComponent } from "./pages/find-ideal/find-ideal.component";
import { ROUTES_PATH } from "./app.component";
import { CheckoutSuccessComponent } from "./pages/checkout-success/checkout-success.component";

export const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent
  },
  {
    path: ROUTES_PATH.QUESTIONS_FLOW,
    component: QuestionsFlowComponent,
    children: [{path: ':question-id', component: QuestionsFlowComponent}]
  },
  {
    path: ROUTES_PATH.EMAIL,
    component: EmailComponent
  },
  {
    path: ROUTES_PATH.FIND_IDEAL,
    component: FindIdealComponent
  },
  {
    path: ROUTES_PATH.CHECKOUT,
    component: CheckoutComponent
  },
  {
    path: ROUTES_PATH.CHECKOUT_ERROR,
    component: CheckoutErrorComponent
  },
  {
    path: ROUTES_PATH.CHECKOUT_SUCCESS,
    component: CheckoutSuccessComponent
  },
  { path: '**', redirectTo: '' }
];

export const AppRouter: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {useHash: false, anchorScrolling: 'enabled'});

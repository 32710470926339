import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { ROUTES_PATH } from "../../app.component";
import { TIME_DELAY, UserModel } from "../../models/user-model";
import { StorageService } from "../../services/storage.service";
import { AmplitudeService } from "../../services/amplitude.service";


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  userState!: UserModel | null;
  utmTags!: string;

  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _renderer: Renderer2,
    private _amplitudeService: AmplitudeService
  ) {
    this.utmTags = JSON.stringify(this.getQueryParams());
  }

  ngOnInit(): void {
    this.setAmplitudeOnPageLoadEvent();
    if (this._storageService.userState) this.userState = this._storageService.userState;
  }

  getQueryParams(): Record<string, string> {
    const queryParams: Record<string, string> = {};
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    urlParams.forEach((value: string, key: string) => {
      queryParams[key] = value;
    });

    return queryParams;
  }

  start(event: any): void {
    this.initUserState();
    this._renderer.addClass(event.target, 'clicked');

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._router.navigate([ROUTES_PATH.QUESTIONS_FLOW]);
    }, TIME_DELAY);
  }

  initUserState(): void {
    this.userState = new UserModel();
    if (this.utmTags && this.utmTags.length > 2) this.userState.utmTags = this.utmTags;

    this._storageService.setUserState(this.userState);
  }

  setAmplitudeOnPageLoadEvent(): void {
    this._amplitudeService.trackEvent('a_welcome_page_shown');
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('a_welcome_page_start_clicked');
  }
}

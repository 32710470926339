import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindIdealComponent } from './find-ideal.component';
import { SharedModule } from "../../shared.module";



@NgModule({
  declarations: [
    FindIdealComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    FindIdealComponent
  ]
})
export class FindIdealModule { }

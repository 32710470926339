import { Injectable } from '@angular/core';
import { UserModel } from "../models/user-model";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _userState!: UserModel;
  private _STORE_KEY = 'user-state';
  private _userStateUpdated = new Subject<void>();

  constructor() { }

  init = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      resolve({});
    });
  }

  get userState(): UserModel | null {
    const userStateStr = localStorage.getItem(this._STORE_KEY);
    if (!userStateStr) return null;

    this._userState = JSON.parse(userStateStr);
    return this._userState;
  }

  subscriptionUserState(): Observable<any> {
    return this._userStateUpdated.asObservable();
  }

  setUserState(newUserState: UserModel): void {
    this._userState = newUserState;
    localStorage.setItem(this._STORE_KEY, JSON.stringify(newUserState));
    this._userStateUpdated.next();
  }
}

import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY, UserModel } from "../../../models/user-model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-dating-goal',
  templateUrl: './dating-goal.component.html',
  styleUrls: ['./dating-goal.component.scss']
})
export class DatingGoalComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string}>();
  lookingForForm: FormGroup;
  userState!: UserModel;
  variants: {value: string, text: string}[] = [
    { value: 'one-night-stand', text: '🌙 1-night stand' },
    { value: 'short-term-fun', text: '⏰ Short-term fun' },
    { value: 'long-term-story', text: '❤️ Long-term story' },
    { value: 'love-relationships', text: '💍 Love and relationships' },
    { value: 'not-sure-yet', text: 'Not sure yet' }
  ];

  constructor(
    private _renderer: Renderer2,
    private _fb: FormBuilder,
    private _storageService: StorageService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {
    this.lookingForForm = this._fb.group({
      variant: ''
    });

    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(): void {
    this.lookingForForm.controls['variant'].valueChanges.subscribe(value => {
      setTimeout(() => {
        this.continue();
      }, (TIME_DELAY - 5));
    });
  }

  continue(): void {
    const value: string = this.lookingForForm.value.variant;

    if (!value) return;

    this.continueEvent.emit({propName: 'datingGoal' , data: value});
    this.setAmplitudeOnBtnClickEvent();

    const uuid: string = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      datingGoal: value
    }).subscribe();
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('a_which_relations_you_prefer_page_continue_clicked');
  }
}

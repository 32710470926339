import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { CreateUserModel, UpdateUserModel } from "../models/user-model";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _apiService: ApiService
  ) { }

  createUserData(requestData: CreateUserModel) {
    return this._apiService.post('fiorry-var-a/create', requestData);
  }

  updateUserData(uuid: string, requestData: UpdateUserModel) {
    return this._apiService.patch('fiorry-var-a/update/' + uuid, requestData);
  }
}

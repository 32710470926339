import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsFlowComponent } from './questions-flow.component';
import { SharedModule } from "../../shared.module";
import { DatingGoalComponent } from './dating-goal/dating-goal.component';
import { GenderComponent } from "./gender/gender.component";
import { LookingForComponent } from "./looking-for/looking-for.component";
import { PreferencesComponent } from "./preferences/preferences.component";
import { PhotosComponent } from "./photos/photos.component";
import { LocationComponent } from "./location/location.component";
import { EmailModule } from "../email/email.module";



@NgModule({
  declarations: [
    QuestionsFlowComponent,
    GenderComponent,
    LookingForComponent,
    DatingGoalComponent,
    PreferencesComponent,
    PhotosComponent,
    LocationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EmailModule
  ],
  exports: [
    QuestionsFlowComponent
  ]
})
export class QuestionsFlowModule { }

import { QuestionsModel } from "./questions-model";

export class UserModel {
  utmTags?: string;
  uuid?: string;
  questions: QuestionsModel;
  email?: string;
  price?: string;
  findIdeal?: boolean;

  constructor() {
    this.questions = {
      gender: {id: 1},
      lookingFor: {id: 2},
      age: {id: 0}, // not in separate page
      datingGoal: {id: 3},
      preferences: {id: 4},
      photos: {id: 5},
      location: {id: 6}
    };
  }
}

export interface UpdateUserModel {
  age?: string[];
  lookingFor?: string;
  datingGoal?: string;
  preferences?: string[];
  location?: string;
  donation_amount?: string;
  email?: string;
  payment_method?: 'CREDIT_CARD' | 'PAY_PAL' | 'APPLE_PAY' | 'GOOGLE_PAY';
  trial?: 1 | 0;
}

export interface CreateUserModel {
  gender: string | undefined;
  project_type: 'TEST' | 'REAL_PAYMENT';
  utm_tags?: string;
}

export const TIME_DELAY = 360;


export const DATING_GOAL_KEYS = {
  ONE_NIGHT_STAND: 'one-night-stand',
  SHORT_TERM_FUN: 'short-term-fun',
  LONG_TERM_STORY: 'long-term-story',
  LOVE_RELATIONSHIPS: 'love-relationships',
  NOT_SURE_YET: 'not-sure-yet'
}

import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY, UserModel } from "../../../models/user-model";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string[]}>();
  preferencesForm: FormGroup;
  userState!: UserModel;

  preferences: {value: string, text: string}[] = [
    { value: 'tattoo', text: 'Tattoo' },
    { value: 'music', text: 'Music' },
    { value: 'bitting', text: 'Bitting' },
    { value: 'kisses', text: 'Kisses' },
    { value: 'threesome', text: 'Threesome' },
    { value: 'dirty-talk', text: 'Dirty talk' },
    { value: 'dominance', text: 'Dominance' },
    { value: 'feet', text: 'Feet' },
    { value: 'role-play', text: 'Role play' },
    { value: 'oral', text: 'Oral' }
  ];

  constructor(
    private _renderer: Renderer2,
    private _fb: FormBuilder,
    private _storageService: StorageService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {
    this.preferencesForm = this._fb.group({
      preferences: this._fb.array([])
    });

    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(): void {
  }

  continue(event: any): void {
    const value: string[] = this.preferencesForm.value.preferences;
    if (!value || value && value.length < 1) return;

    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'preferences' , data: value});

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));

    const uuid: string = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      preferences: value
    }).subscribe();
  }

  onCheckboxChange(e: any) {
    const preferencesArray: FormArray = this.preferencesForm.get('preferences') as FormArray;

    if (e.target.checked) {
      preferencesArray.push(this._fb.control(e.target.value));
    } else {
      const index = preferencesArray.controls.findIndex(x => x.value === e.target.value);
      preferencesArray.removeAt(index);
    }
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('a_turns_you_on_page_continue_clicked');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UserModel } from "../../models/user-model";

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input() set userStateData(userState: UserModel) {
    if (userState !== undefined) this.userState = userState;
  }

  userState!: UserModel;

  constructor() { }

  ngOnInit(): void {
  }

}

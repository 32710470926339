import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ROUTES_PATH } from "../../app.component";
import { Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { DATING_GOAL_KEYS, TIME_DELAY, UpdateUserModel, UserModel } from "../../models/user-model";
import { CountdownComponent, CountdownConfig } from "ngx-countdown";
import { UserService } from "../../services/user.service";
import { AmplitudeService } from "../../services/amplitude.service";

@Component({
  selector: 'app-find-ideal',
  templateUrl: './find-ideal.component.html',
  styleUrls: ['./find-ideal.component.scss']
})
export class FindIdealComponent implements OnInit {
  @ViewChild('cd', { static: false }) private countdown!: CountdownComponent;
  @ViewChild('buttonTryTrial', {static: false}) private buttonTryTrial!: ElementRef;

  isButtonTryTrialIntoView: boolean = true;
  userState!: UserModel;
  countDownConfig!: CountdownConfig;
  showedPlans: boolean = false;
  plans: Plan[] = [
    {
      id: 'yearly',
      name: 'year',
      active: false,
      fullPricePerWeek: 4.32,
      discount: 69,
      discountPrice: 69.99,
      discountPricePerWeek: 1.34
    },
    {
      id: 'monthly',
      name: 'month',
      active: false,
      fullPricePerWeek: 15,
      discount: 63,
      discountPrice: 29.99,
      discountPricePerWeek: 7
    },
    {
      id: 'weekly',
      name: 'week',
      active: false,
      fullPricePerWeek: 18,
      discount: 34,
      discountPrice: 12,
      discountPricePerWeek: 12
    }
  ];

  activePlan!: Plan;

  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _renderer: Renderer2,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.setAmplitudeOnPageLoadEvent();
    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });

    this.setActivePlan(this.plans[0].id);

    this.setCountDownConfig();
  }

  setActivePlan(id: string): void {
    this.plans.forEach((plan: Plan) => {
      if (plan.id === id) {
        plan.active = true;
        this.activePlan = plan;
      } else {
        plan.active = false;
      }
    });
  }

  startTrial(event: any, downBtnClicked?: boolean): void {
    this._renderer.addClass(event.target, 'clicked');
    this.userState.price = '$' + this.activePlan.discountPrice;
    this.userState.findIdeal = true;
    this._storageService.setUserState(this.userState);

    const secondsLeft = this.countdown.left / 1000;

    this.setAmplitudeOnBtnClickEvent(secondsLeft, downBtnClicked);

    setTimeout(() => {
      this._router.navigate([ROUTES_PATH.CHECKOUT], {queryParams: {time: secondsLeft}});
    }, TIME_DELAY);


    if (this.userState.uuid) {
      const requestData: UpdateUserModel = {
        donation_amount: this.userState.price ? this.userState.price : 'error happen',
      };
      this._userService.updateUserData(this.userState.uuid, requestData).subscribe((response) => {

      }, error => {
        console.error('FindIdealComponent#updateUserData error: ', error);
      });
    }
  }

  goBack() {
    this._amplitudeService.trackEvent('a_back_button_clicked', {source_page: 'questions-flow-6'});
    this._router.navigate([ROUTES_PATH.QUESTIONS_FLOW], {queryParams: {force: 'last'}});
  }

  setAmplitudeOnBtnClickEvent(secondsLeft: number, downBtnClicked?: boolean): void {
    const eventOptions: Record<string, any> = {
      offer_type: 'none',
      sub_type: this.activePlan.name,
      time_to_convert: secondsLeft,
      scroll_used: downBtnClicked ? 'yes' : 'no'
    };
    this._amplitudeService.trackEvent('a_payment_cta_clicked', eventOptions);
  }

  private setCountDownConfig(): void {
    const expiresInSeconds = 60; // 15min
    const format = 'mm:ss';
    this.countDownConfig = {
      leftTime: expiresInSeconds,
      format: format,
      prettyText: (text) => {
        let res = text.split(':')
          .map((v, index) => {
            let item = '';
            if (index === 1) item += `<span class="count-down-item-separator">:</span>`;
            item += `<span class="count-down-item">${v}</span>`;
            return item;
          }).join('');
        return res;
      },
    };
  }

  setAmplitudeOnPageLoadEvent(): void {
    this._amplitudeService.trackEvent('a_payment_page_shown');
  }

  setAmplitudeOnAllPlansClickEvent(): void {
    this._amplitudeService.trackEvent('a_payment_plans_clicked');
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(){
    if (this.buttonTryTrial){
      const rect = this.buttonTryTrial.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      // @ts-ignore
      const bottomShown = rect.bottom <= window['innerHeight'];
      this.isButtonTryTrialIntoView = topShown && bottomShown;
    }
  }

  protected readonly DATING_GOAL_KEYS = DATING_GOAL_KEYS;
}

interface Plan {
  id: string;
  name: string;
  active: boolean;
  fullPricePerWeek: number;
  discount: number;
  discountPrice: number;
  // discountPricePerYear?: number;
  // discountPricePerMonth?: number;
  discountPricePerWeek?: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from './email.component';
import { SharedModule } from "../../shared.module";



@NgModule({
  declarations: [
    EmailComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    EmailComponent
  ]
})
export class EmailModule { }

import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY, UserModel } from "../../../models/user-model";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";
import { AmplitudeService } from "../../../services/amplitude.service";
import { GeoLocationService } from "../../../services/get-location";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string}>();
  location: string = '';
  suggestions: any[] = [];
  selectedCity: string | null = null;
  userState!: UserModel;

  constructor(
    private _renderer: Renderer2,
    private _http: HttpClient,
    private _storageService: StorageService,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService,
    private _geoLocationService: GeoLocationService // Inject GeoLocationService
  ) { }

  ngOnInit(): void {
    this.getUserLocation(); // Fetch location based on IP

    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  onSearchChange(): void {
    if (this.location.length > 2) { // start searching after 3 characters
      this._http.get(`https://nominatim.openstreetmap.org/search?city=${this.location}&format=json&addressdetails=1&limit=5&accept-language=en`)
        .subscribe((data: any) => {
          this.suggestions = data;
        });
    } else {
      this.suggestions = [];
    }
  }

  selectSuggestion(suggestion: any): void {
    this.selectedCity = `${suggestion.address.city || suggestion.address.town}, ${suggestion.address.country}`;
    this.location = this.selectedCity;
    this.suggestions = [];
  }

  // Update to use GeoLocationService to get location via IP
  getUserLocation(): void {
    this.setAmplitudeOnPickLocationClickEvent();

    this._geoLocationService.getLocation().subscribe((location: any) => {
      // ipinfo.io returns the city and country in a different format
      if (location && location.city && location.country) {
        this.selectedCity = `${location.city}, ${location.country}`;
        this.location = this.selectedCity;
      } else {
        this.location = "Location not found";
      }
    }, (error: any) => {
      console.error("Error fetching location:", error);
      this.location = "Unable to get location";
    });
  }

  continue(event: any): void {
    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'location', data: this.location});

    this.setAmplitudeOnContinueClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));

    const uuid: string = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      location: this.location
    }).subscribe();
  }

  setAmplitudeOnPickLocationClickEvent(): void {
    this._amplitudeService.trackEvent('a_location_page_change_clicked');
  }

  setAmplitudeOnContinueClickEvent(): void {
    this._amplitudeService.trackEvent('a_location_page_continue_clicked');
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionsModel } from "../../models/questions-model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() goBackEvent = new EventEmitter<{propName: keyof QuestionsModel, data: boolean}>();
  @Input() showBackButton!: boolean;
  @Input() darkMode!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  goBack(): void {
    this.goBackEvent.emit();
  }
}

import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  currentUrl!: string;
  routes: any = {};
  hasActiveRoute!: boolean;

  constructor(
    private _renderer: Renderer2,
    private _router: Router
  ) {
  }

  ngAfterViewInit() {
    this._router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.currentUrl = event.url;
        this.setActiveComponent();
      }
    });

    setTimeout(() => {
      this.hideLoader();
    }, 1400);
  }

  setActiveComponent(): void {
    this.hasActiveRoute = false;
    const keys = (Object.keys(ROUTES_PATH) as Array<keyof ROUTES_PATH>);

    keys.forEach((key) => {
      const active = this.currentUrl.includes(ROUTES_PATH[key as keyof typeof ROUTES_PATH]);
      if (active) this.hasActiveRoute = true;
      this.routes[key] = active;
    });

    if (!this.hasActiveRoute) this.currentUrl = "/";
  }

  hideLoader(): void {
    let loader = this._renderer.selectRootElement('#main-loader');
    loader.style.opacity = 0;
    loader.style.visibility = "hidden";
  }
}

export enum ROUTES_PATH {
  QUESTIONS_FLOW = 'questions-flow',
  EMAIL = 'email',
  FIND_IDEAL= 'find-ideal',
  CHECKOUT = 'checkout',
  CHECKOUT_ERROR = 'error',
  CHECKOUT_SUCCESS = 'success'
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppsflyerService } from "../../services/appsflyer.service";
import { ROUTES_PATH } from "../../app.component";
import { TIME_DELAY } from "../../models/user-model";

@Component({
  selector: 'app-checkout-error',
  templateUrl: './checkout-error.component.html',
  styleUrls: ['./checkout-error.component.scss']
})
export class CheckoutErrorComponent implements OnInit {
  appsflyerUrl!: string;

  constructor(
    private _renderer: Renderer2,
    private _appsflyerService: AppsflyerService
  ) {
    this.appsflyerUrl = this._appsflyerService.generateOneLinkURL();
  }

  ngOnInit(): void {
  }

  navigateToShop(event: any): void {
    this._renderer.addClass(event.target, 'clicked');

    window.location.href = this.appsflyerUrl;

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, TIME_DELAY);
  }
}

<h1 class="question">
  Upload your photos
</h1>


<div class="photo-boxes">
  <div class="photo-box" *ngFor="let photo of photos; let i = index">
    <input type="file" id="photo{{i}}" (change)="onFileSelected($event, i)" accept="image/*" hidden
           [attr.disabled]="i === 1 ? 'true' : null">
    <label for="photo{{i}}" class="photo-label" [ngClass]="{'uploaded': photos[i]}">
      <ng-container *ngIf="photos[i]; else addPhotoTemplate">
        <img [src]="photos[i]" alt="Uploaded Photo {{i + 1}}">
      </ng-container>
      <ng-template #addPhotoTemplate>
        <div class="add-photo" (click)="setAmplitudeOnPlusClickEvent()" *ngIf="i === 0">
          <i class="icon icon-plus"></i>
          <p>Add your photo</p>
        </div>
      </ng-template>
    </label>
  </div>
</div>

<button  (click)="continue($event)" class="button button-primary size-normal" id="btn-continue-dating-goal"
         [disabled]="!atLeastOnePhotoUploaded">
  Continue
</button>

<p class="add-later" (click)="continue()">Add later</p>

import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { QuestionsProgressComponent } from "./components/questions-progress/questions-progress.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";
import { CountdownModule } from "ngx-countdown";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import {DatingGoalPipe} from "./pipes/dating-goal";

const COMPONENTS = [
  HeaderComponent,
  QuestionsProgressComponent,
  UserInfoComponent
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CountdownModule,
  NgxSliderModule
];

const PIPES = [
  DatingGoalPipe
]

@NgModule({
  imports: [
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES,
    ...PIPES
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<RouterModule> {
    return <ModuleWithProviders<RouterModule>>{
      ngModule: SharedModule,
      providers: []
    };
  }
}

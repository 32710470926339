<h1 class="question">
  Which one<br/>do you prefer?
</h1>

<div class="dating-goal-container">
  <form [formGroup]="lookingForForm" class="mb-6">
    <div class="dating-goal-box" *ngFor="let variant of variants">
      <input type="radio"
             id="{{variant.value}}"
             formControlName="variant"
             [value]="variant.value">
      <label for="{{variant.value}}" class="dating-goal-button">{{variant.text}}</label>
    </div>
  </form>
</div>


<h1 class="question">
  What turns you on?
</h1>

<p class="info">
  Choose everything you like
</p>

<div class="preferences-container">
  <form [formGroup]="preferencesForm">
    <div class="preferences-box" *ngFor="let preference of preferences; let i = index;">
      <input type="checkbox"
             id="{{preference.value}}"
             [value]="preference.value"
             [checked]="(preferencesForm.get('preferences')?.value ?? []).includes(preference.value)"
             (change)="onCheckboxChange($event)">
      <label for="{{preference.value}}" class="preferences-button"><span class="icon-pref icon-pref-{{i + 1}}"></span>{{preference.text}}</label>
    </div>
  </form>
</div>

<button  (click)="continue($event)" class="button button-primary size-normal" id="btn-continue-dating-goal">
  Continue
</button>


import { Injectable } from '@angular/core';
import { catchError, filter, Observable, ObservableInput, of, switchMapTo, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly _baseUrl: string;
  private _headers: any;

  constructor(
    private _httpClient: HttpClient
  ) {
    this._baseUrl = 'https://api.paradiz.app/';
  }

  get(url: string, params?: any): Observable<any> {

    return this.inspectToken()
      .pipe(
        filter((res: any) => res),
        switchMapTo(this._httpClient.get(this._baseUrl + url, {headers: this._headers, params}) as ObservableInput<any>),
        catchError((error: any) => {
          this.checkErrors(error.error, error.status);
          return throwError(error);
        })
      );
  }

  post(url: string, body?: any): Observable<any> {
    return this.inspectToken()
      .pipe(
        filter((res: any) => res),
        switchMapTo(this._httpClient.post(this._baseUrl + url, body, {headers: this._headers}) as ObservableInput<any>),
        catchError((error: any) => {
          this.checkErrors(error.error, error.status);
          return throwError(error);
        })
      );
  }

  put(url: string, body?: any): Observable<any> {
    return this.inspectToken()
      .pipe(
        filter((res: any) => res),
        switchMapTo(this._httpClient.put(this._baseUrl + url, body, {headers: this._headers}) as ObservableInput<any>),
        catchError((error: any) => {
          this.checkErrors(error.error, error.status);
          return throwError(error);
        })
      );
  }

  patch(url: string, body: any): Observable<any> {
    return this.inspectToken()
      .pipe(
        filter((res: any) => res),
        switchMapTo(this._httpClient.patch(this._baseUrl + url, body, {headers: this._headers}) as ObservableInput<any>),
        catchError((error: any) => {
          this.checkErrors(error.error, error.status);
          return throwError(error);
        })
      );
  }

  checkErrors(error: any, status: number) {
    console.error('ApiService#Request Error: ', error, status);
  }

  inspectToken(): Observable<boolean> {
    this._headers = new HttpHeaders();

    // this._headers = this._headers.append('Access-Control-Allow-Origin', '*');
    this._headers = this._headers.append('Content-Type', 'application/json');

    return of(true);
  }
}

<app-header [darkMode]="true"></app-header>

<section class="container text-center">
  <div class="img-wrap">
    <img src="../../../assets/images/img-main.png" alt="">
  </div>
  <h1>Find your transgender <br/>date today</h1>
  <p class="description">Our community boasts over 350,000 attractive members, with verified profiles and 100% private chats.</p>
  <button  (click)="start($event)" class="button button-primary size-normal" id="btn-get-started-home">
    Get started
  </button>
</section>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { SharedModule } from "../../shared.module";
import { NgxStripeModule } from "ngx-stripe";



@NgModule({
  declarations: [
    CheckoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxStripeModule
  ],
  exports: [
    CheckoutComponent
  ]
})
export class CheckoutModule { }

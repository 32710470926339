import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutErrorComponent } from './checkout-error.component';
import { SharedModule } from "../../shared.module";



@NgModule({
  declarations: [
    CheckoutErrorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [CheckoutErrorComponent]
})
export class CheckoutErrorModule { }

import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTES_PATH } from "../../app.component";
import { StorageService } from "../../services/storage.service";
import { CreateUserModel, TIME_DELAY, UserModel } from "../../models/user-model";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { QuestionsModel } from "../../models/questions-model";
import { AmplitudeService } from "../../services/amplitude.service";

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @Output() hidePopUpEvent = new EventEmitter<void>();

  userState!: UserModel;
  form: any;

  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _fb: UntypedFormBuilder,
    private _renderer: Renderer2,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _el: ElementRef,
    private _amplitudeService: AmplitudeService
  ) {
    if (this._storageService.userState) this.userState = this._storageService.userState;

    this._storageService.subscriptionUserState().subscribe(() => {
      if (this._storageService.userState) this.userState = this._storageService.userState;
    });
  }

  ngOnInit(): void {
    const PATTERN = '^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'; // email validator

    const EMAIL_VALIDATORS = [
      this.regexValidator(new RegExp(PATTERN), {'customPattern': 'email'})
    ];

    this.form = this._fb.group({
      email: new UntypedFormControl(this.userState && this.userState.email ?  this.userState.email : '', [...EMAIL_VALIDATORS, Validators.required]),
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null  => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  send(form: any, event: any): void {
    this._renderer.addClass(event.target, 'clicked');
    this.userState.email = form.value.email;
    this._storageService.setUserState(this.userState);

    this.setAmplitudeOnContinueClickEvent();

    setTimeout(() => {
      this._router.navigate([ROUTES_PATH.FIND_IDEAL]);
    }, TIME_DELAY);


    const uuid: string = this.userState.uuid ? this.userState.uuid : '';
    this._userService.updateUserData(uuid, {
      email: this.userState.email ? this.userState.email : 'error happen'
    }).subscribe();
  }

  onRootClick(): void {
    this.hidePopUpEvent.emit();
  }

  setAmplitudeOnContinueClickEvent(): void {
    this._amplitudeService.trackEvent('a_sign_up_hover_continue_clicked');
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const sectionElement = this._el.nativeElement.querySelector('section.container');

    if (sectionElement && !sectionElement.contains(event.target as Node)) {
      this.onRootClick();
    }
  }
}

<section class="container text-center">
  <div class="checkout-header">
    <img src="../../../assets/images/fiorry-logo-dark.svg" alt="">
  </div>

  <img src="../../../assets/images/icon-circle-error.svg" class="circle-img" alt="">

  <h3>
    We're currently experiencing some<br/>technical issues with our website.<br/>Rest assured, no charges have been made.
  </h3>

  <p class="info">
    For a smooth experience, please download the Fiorry app directly and follow the
    onboarding instructions.
  </p>

  <button class="button button-primary size-normal" id="btn-install-checkout-error"
          (click)="navigateToShop($event)"><i class="icon icon-install"></i> Install</button>

</section>

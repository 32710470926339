import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY } from "../../../models/user-model";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{ propName: keyof QuestionsModel, data: (string | null)[] }>();
  photos: (string | null)[] = [null, null];

  constructor(
    private _renderer: Renderer2,
    private _amplitudeService: AmplitudeService
  ) { }

  ngOnInit(): void {
  }


  onFileSelected(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        let indexPlace: number = 0;
        if (!this.photos[1]) indexPlace = 1;
        this.photos[indexPlace] = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  get atLeastOnePhotoUploaded(): boolean {
    return this.photos.some(photo => photo !== null);
  }

  continue(event?: any): void {
    if (event) {
      this._renderer.addClass(event.target, 'clicked');

      this.setAmplitudeOnContinueClickEvent();

      setTimeout(() => {
        this._renderer.removeClass(event.target, 'clicked');
      }, (TIME_DELAY - 5));

    } else {
      this.setAmplitudeOnSkipClickEvent();
    }

    this.continueEvent.emit({propName: 'photos' , data: this.photos});
  }

  setAmplitudeOnPlusClickEvent(): void {
    this._amplitudeService.trackEvent('a_add_photos_page_plus_clicked');
  }

  setAmplitudeOnContinueClickEvent(): void {
    this._amplitudeService.trackEvent('a_add_photos_page_continue_clicked');
  }

  setAmplitudeOnSkipClickEvent(): void {
    this._amplitudeService.trackEvent('a_add_photos_page_skip_clicked');
  }
}

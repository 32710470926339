<div class="info-wrap">
  <div class="user-email">
    <span *ngIf="userState">{{userState.email}}</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <g clip-path="url(#clip0_208_576)">
        <circle cx="10.5" cy="10.5" r="10.5" fill="white"/>
        <path opacity="0.87" d="M10.5 4C11.6272 4 12.7082 4.44777 13.5052 5.2448C14.3022 6.04183 14.75 7.12283 14.75 8.25C14.75 9.37717 14.3022 10.4582 13.5052 11.2552C12.7082 12.0522 11.6272 12.5 10.5 12.5C9.37283 12.5 8.29183 12.0522 7.4948 11.2552C6.69777 10.4582 6.25 9.37717 6.25 8.25C6.25 7.12283 6.69777 6.04183 7.4948 5.2448C8.29183 4.44777 9.37283 4 10.5 4ZM10.5 14.625C15.1962 14.625 19 16.5269 19 18.875V21H2V18.875C2 16.5269 5.80375 14.625 10.5 14.625Z" fill="#19181D"/>
      </g>
      <defs>
        <clipPath id="clip0_208_576">
          <rect width="21" height="21" rx="10.5" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</div>

<app-header [showBackButton]="true" (goBackEvent)="goBack()" [darkMode]="true"></app-header>

<section class="container text-center">
  <app-questions-progress [id]="activeQuestionId"></app-questions-progress>

  <app-gender class="step" [ngClass]="{'active': activeQuestionId === 1}"
                 (continueEvent)="continue($event)"></app-gender>

  <app-looking-for class="step" [ngClass]="{'active': activeQuestionId === 2}"
                   (continueEvent)="continue($event)"></app-looking-for>

  <app-dating-goal class="step" [ngClass]="{'active': activeQuestionId === 3}"
                   (continueEvent)="continue($event)"></app-dating-goal>

  <app-preferences class="step" [ngClass]="{'active': activeQuestionId === 4}"
                   (continueEvent)="continue($event)"></app-preferences>

  <app-photos class="step" [ngClass]="{'active': activeQuestionId === 5}"
              (continueEvent)="continue($event)"></app-photos>

  <app-location class="step" [ngClass]="{'active': activeQuestionId === 6}"
                (continueEvent)="continue($event)"></app-location>

  <app-email *ngIf="showEmailPopup" (hidePopUpEvent)="hideEmailPopup()"></app-email>
</section>

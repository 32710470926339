<section class="container text-center">
  <h3>Your account is ready</h3>

  <form [formGroup]="form">

    <div class="control">
      <input type="text" placeholder="E-mail" formControlName="email">
    </div>

    <p class="info">
      Your personal data is safe with us. We'll use your email to finish creating your account and to enable account recovery.
    </p>

    <p class="description">We got <b>8500+</b> partners matching your preferences</p>

    <button  class="button button-primary size-normal" id="btn-continue-email"
             (click)="send(form, $event)" [disabled]="!form?.valid">
      Continue
    </button>

  </form>
</section>

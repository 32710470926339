import { Injectable } from '@angular/core';

declare global {
  interface Window {
    AF_SMART_SCRIPT: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppsflyerService {

  constructor() { }

  generateOneLinkURL() {
    const oneLinkURL = "https://paradiz.onelink.me/O5mM";
    const mediaSource = {keys:["utm_source"],defaultValue:"null"};
    const campaign = {keys:["utm_campaign"],defaultValue:"null"};
    const channel = {keys:["nonexistent"],defaultValue:"funnel_d"};
    const fbclid = {paramKey:"fbclid",keys:["fbclid"],defaultValue:"null"};
    const adSet = {keys:["utm_adset"],defaultValue:"null"};
    const custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};


    let result: any = {};
    if (window && window.AF_SMART_SCRIPT) {
      result = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: oneLinkURL,
        afParameters: {
          mediaSource: mediaSource,
          campaign: campaign,
          channel: channel,
          afCustom: [
            fbclid,
            custom_ss_ui
          ],
          adSet: adSet
        }
      });
    } else {
      result = {
        clickURL: 'https://paradiz.app/'
      }
    }

    return result ? result.clickURL : "No output from script";
  }
}

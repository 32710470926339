import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppsflyerService } from "../../services/appsflyer.service";
import { ActivatedRoute } from '@angular/router';
import { ROUTES_PATH } from "../../app.component";
import { TIME_DELAY } from "../../models/user-model";

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {
  appsflyerUrl!: string;
  voucher: string | null = null;

  constructor(
    private _renderer: Renderer2,
    private _appsflyerService: AppsflyerService,
    private route: ActivatedRoute
  ) {
    this.appsflyerUrl = this._appsflyerService.generateOneLinkURL();
  }

  ngOnInit(): void {
    // Retrieve the voucher code from the query parameters
    this.voucher = this.route.snapshot.queryParamMap.get('voucher');
  }

  navigateToShop(event: any): void {
    this._renderer.addClass(event.target, 'clicked');

    window.location.href = this.appsflyerUrl;

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, TIME_DELAY);
  }
}

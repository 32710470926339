<header>
  <div class="container">
    <div class="wrap" [ngClass]="{'dark': darkMode}">
      <svg xmlns="http://www.w3.org/2000/svg" *ngIf="showBackButton" id="btn-go-back-header" (click)="goBack()" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976314 8.31658 0.292893 8.70711L6.65686 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538409 7.04738 0.538409 6.65685 0.928933L0.292892 7.29289ZM17 7L1 7L1 9L17 9L17 7Z" fill="#888888"/>
      </svg>
      <img src="../../../assets/images/fiorry-logo-dark.svg">
    </div>
  </div>
</header>

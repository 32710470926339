<img src="../../../../assets/images/steps/step-3.png" alt="">
<h1 class="question">
  Who attracts<br/>you the most?
</h1>

<div class="gender-container">
  <form [formGroup]="genderForm">
    <div class="gender-box" *ngFor="let gender of genders">
      <input type="radio" id="{{gender.value + '-2'}}" formControlName="gender" [value]="gender.value">
      <label for="{{gender.value + '-2'}}" class="gender-button">{{gender.text}}</label>
    </div>
  </form>
</div>

<div class="age-container">
  <ngx-slider [options]="ngxSliderOption" [(value)]="sliderLowValue" [(highValue)]="sliderHighValue" ></ngx-slider>
</div>

<button  (click)="continue($event)" class="button button-primary size-normal" id="btn-continue-looking-for">
  Continue
</button>


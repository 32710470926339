import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  constructor(private http: HttpClient) {}

  getLocation(): Observable<any> {
    return this.http.get('https://ipinfo.io/json'); // https://pro.ip-api.com/json/?key=YOUR_API_KEY` after upgrade to paid version
  }
}
